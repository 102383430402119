import React from 'react'

import './Logo.css'

export default ({ inverted }) => (
  <div
    className="Logo"
    style={{
      backgroundImage: 'url(/images/code.svg)',
    }}
  />
)
